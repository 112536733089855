import React from 'react';
import {graphql} from 'gatsby';
import {NavigationContext} from '../services/navigation';
import {PageContent} from '../components/organisms/Layout';
import {DynamicZone, Header, Intro, SystemInfo, SEO} from '../components/organisms';
import {ContactUs, USP} from '../components/organisms/blocks';

const System = ({data}) => {
  const {
    strapi: {
      system: {
        name,
        shortDescription,
        image,
        intro,
        content,
        info,
        usp,
        contact,
        SEO: _SEO
      }
    }
  } = data;
  const {setLocation} = React.useContext(NavigationContext);
  React.useEffect(() => {
    setLocation(window.location.pathname);
  }, []);
  const headerProps = {
    tag: shortDescription,
    text: name,
    media: {file: image},
    showWave: true,
    waveColor: 'lightBlue',
    variant: 'XL'
  };
  return (
    <PageContent>
      <SEO
        title={_SEO?.title}
        description={_SEO?.description}
        meta={[
          {
            property: 'keywords',
            content: _SEO?.keywords,
          },
          {
            property: 'og:image',
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${image?.file?.publicURL}`
          }
        ]}
      />
      <Header header={headerProps} />
      {intro && <Intro intro={intro} />}
      <USP {...usp}/>
      {info && <SystemInfo {...info} />}
      <DynamicZone blocks={content} />
      {contact && <ContactUs {...contact} />}
    </PageContent>
  );
};

export default System;

export const marketQuery = graphql`
  query GET_SYSTEM($id: ID!) {
    strapi {
      system(id: $id) {
        SEO {
          ...SEO
        }
        ...System
      }
    }
  }
`;
